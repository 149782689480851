export const CalenderIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90225 4.31247C2.50604 4.31247 2.18486 4.63427 2.18486 5.03122V15.0937C2.18486 15.4907 2.50604 15.8124 2.90225 15.8124H15.0979C15.4941 15.8124 15.8153 15.4907 15.8153 15.0937V5.03122C15.8153 4.63427 15.4941 4.31247 15.0979 4.31247H2.90225ZM1.46747 2.87497C1.07126 2.87497 0.750076 3.19676 0.750076 3.59372V16.5312C0.750076 16.9281 1.07126 17.25 1.46747 17.25H16.5327C16.9289 17.25 17.2501 16.9281 17.2501 16.5312V3.59372C17.2501 3.19676 16.9289 2.87497 16.5327 2.87497H1.46747Z"
        fill="currentColor"
      />
      <path
        d="M1.4671 3.59375H17.2497V6.46875H1.4671V3.59375ZM3.61928 0.71875C3.61928 0.321795 3.94047 0 4.33667 0C4.73287 0 5.05406 0.321795 5.05406 0.71875V2.875C5.05406 3.27196 4.73287 3.59375 4.33667 3.59375C3.94047 3.59375 3.61928 3.27196 3.61928 2.875V0.71875ZM12.9454 0.71875C12.9454 0.321795 13.2665 0 13.6628 0C14.059 0 14.3801 0.321795 14.3801 0.71875V2.875C14.3801 3.27196 14.059 3.59375 13.6628 3.59375C13.2665 3.59375 12.9454 3.27196 12.9454 2.875V0.71875ZM4.33667 7.90628H6.48884V10.0625H4.33667V7.90628ZM4.33667 12.2188H6.48884V14.375H4.33667V12.2188ZM7.92363 7.90628H10.0758V10.0625H7.92363V7.90628ZM7.92363 12.2188H10.0758V14.375H7.92363V12.2188ZM11.5106 7.90628H13.6628V10.0625H11.5106V7.90628Z"
        fill="currentColor"
      />
    </svg>
  );
};
