const styles = {
  menuHeading:
    'font-semibold font-stratos text-base md:text-[22px] text-left uppercase',

  subHeading: 'font-bold font-stratos text-xl md:text-3xl',
  smHeading: 'font-semibold font-stratos md:text-[22px] uppercase',

  interLg:
    'font-normal font-interstate text-base md:text-lg text-dark dark:text-brand-gray_1',
  interRegular:
    'font-normal font-interstate text-sm md:text-base text-dark dark:text-brand-gray_1',
  interSm: 'font-normal font-interstate text-sm text-brand-dark',
  interBoldSm: 'apply font-bold text-sm text-brand-gray_1',
  interBoldXl: 'font-bold text-[10px] md:text-xl text-brand-gray_1',
  neuMediumBase: 'font-bold neue-haas-grotesk-display text-[15px] md:text-base',
  stratosBoldBase: 'font-bold font-stratos text-sm md:text-base',

  button:
    'font-bold font-stratos text-base uppercase w-[110px] lg:w-[160px] h-[35px] md:h-[43px] rounded-xl',
  borderBttn:
    'font-normal text-brand-gray_1 w-[170px] h-[43px] rounded-xl bg-transparent border-[1px] border-solid border-gray_1 text-center uppercase text-gray_1',
  anchorLink: 'font-normal font-interstate text-base text-brand',

  giveawayGrid:
    'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 lg:gap-x-5 gap-y-0 lg:gap-y-7 lg:mt-[40px] md:mt-0 w-full',
  giveawayCard:
    'relative bg-white dark:bg-brand-dark_2 p-1.5 lg:p-3 mt-5 rounded-2xl text-brand-dark dark:text-white min-h-[150px] lg:min-h-[540px] lg:pb-0',

  ticketNumber:
    '  rounded-full border-[1px] border-solid flex justify-center items-center font-semibold font-neue-haas-grotesk-display text-base md:text-2xl',

  //FOOTER
  footerHeading:
    'font-bold font-interstate text-base md:text-xl text-brand-gray_1 mb-3 md:mb-5',
};

export default styles;
