export const ChatIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1195 26.1132C15.0068 29.1334 21.0285 26.6784 23.868 23.8388C28.7496 18.9573 28.7496 11.0427 23.868 6.16117C18.9865 1.27961 11.0719 1.27961 6.19035 6.16117C3.22986 9.12165 0.884445 15.0336 3.91504 19.9102C4.08287 20.1803 4.1668 20.3153 4.20784 20.4184C4.28608 20.6149 4.30906 20.7657 4.29289 20.9765C4.2844 21.0871 4.25201 21.2121 4.18724 21.462C3.77973 23.0343 3.57597 23.8204 3.69123 24.3867C3.89128 25.3697 4.65949 26.1379 5.64245 26.338C6.20879 26.4532 6.99492 26.2495 8.56716 25.8419C8.81699 25.7772 8.94196 25.7448 9.05222 25.7363C9.26304 25.72 9.41214 25.7425 9.60874 25.8204C9.71156 25.8611 9.84755 25.9451 10.1195 26.1132ZM11.3159 14.8222C11.3159 15.5126 10.7563 16.0722 10.0659 16.0722C9.37556 16.0722 8.81592 15.5126 8.81592 14.8222C8.81592 14.1319 9.37556 13.5722 10.0659 13.5722C10.7563 13.5722 11.3159 14.1319 11.3159 14.8222ZM16.3159 14.8222C16.3159 15.5126 15.7563 16.0722 15.0659 16.0722C14.3756 16.0722 13.8159 15.5126 13.8159 14.8222C13.8159 14.1319 14.3756 13.5722 15.0659 13.5722C15.7563 13.5722 16.3159 14.1319 16.3159 14.8222ZM20.0659 16.0722C20.7563 16.0722 21.3159 15.5126 21.3159 14.8222C21.3159 14.1319 20.7563 13.5722 20.0659 13.5722C19.3756 13.5722 18.8159 14.1319 18.8159 14.8222C18.8159 15.5126 19.3756 16.0722 20.0659 16.0722Z"
        fill="currentColor"
      />
    </svg>
  );
};
