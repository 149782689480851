'use client';
import React from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import moment from 'moment';
import Image from 'next/image';
import { UserIcon } from '@/app/components/icons/user-icon';
import { ChatIcon } from '@/app/components/icons/chat-icon';
import { CalenderIcon } from '@/app/components/icons/calendar-icon';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

const PostDetailsLink = dynamic(() => import('./PostDetailsLink'));

const PostCard = ({ post }) => {
  return (
    <div className="bg-white dark:bg-brand-dark_2 p-3 rounded-2xl h-auto max-h-max">
      <div className="relative h-[180px] 3xl:h-[300px] w-auto">
        <Image
          src={post?.featuredImage?.node?.sourceUrl}
          // width={300}
          // height={370}
          className="rounded-lg"
          // layout="responsive"
          fill
          alt={post?.title || ''}
        />
      </div>
      <div className="px-2 py-3">
        <h6 className="font-neue-haas-grotesk-display font-medium text-base uppercase text-brand mt-3">
          {post?.title}
        </h6>
        <div
          dangerouslySetInnerHTML={{
            __html: post?.excerpt,
          }}
          className=" line-clamp-3 h-[70px] max-h-[70px]  min-h-[70px] mt-3 mb-6 font-neue-haas-grotesk-display font-medium text-base text-brand-darkGray_2 dark:text-brand-gray_1  "
        />
        <div className="flexBetween">
          <PostDetailsLink postsData={post}>
            <div className="text-brand text-xs flex items-center">
              <span className="font-medium font-neue-haas-grotesk-display uppercase text-xs">
                Read more
              </span>
              <MdKeyboardDoubleArrowRight />
            </div>
          </PostDetailsLink>
          <div className="flex gap-3 font-normal font-stratos text-xs text-brand-dark dark:text-white">
            <span>
              {' '}
              {`${moment(post?.date).format('DD')} ${moment(post?.date).format(
                'MMM',
              )} ${moment(post?.date).format('YYYY')}`}
            </span>
            <span>{post?.commentCount || 0} Comment(s)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PostCard);
