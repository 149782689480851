'use client';
import React, { useEffect, useRef } from 'react';
import useSWR, { preload } from 'swr';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from 'next/image';
import Heading from '../ui/Heading';
import { AwardIcon } from '../icons/award-icon';
import { getRecentWinners } from './actions';
import { ChevronRight } from '../icons/chevron-right';
import { ChevronLeft } from '../icons/chevron-left';

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <ChevronRight className=" absolute top-[30%] z-40 right-[-20px] w-10 h-10 cursor-pointer " />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <ChevronLeft className=" absolute top-[30%] z-40 left-[-20px] w-10 h-10 cursor-pointer " />
    </div>
  );
}

const settings = {
  dots: false,
  // arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,

  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const fetcher = async () => {
  return await getRecentWinners().then((res) => {
    return res.recentWinners;
  });
};

const RecentWinners = ({ recentWinners }) => {
  const winners = useRef(recentWinners);

  const { data, error, isLoading } = useSWR('recentwinnersdata', fetcher);

  useEffect(() => {
    if (data) {
      winners.current = data;
    }
  }, [data]);

  return (
    <div className="recent_winners pt-10 pb-10 mb-10 lg:mb-0 md:pb-20 font-interstate">
      <Heading title="RECENT WINNERS" />
      <div className="bg-white dark:bg-brand-dark_2 px-6 py-12">
        <Slider {...settings} className="pb-8">
          {winners?.current?.map((item, index) => (
            <div
              key={index}
              className="text-brand-darkGray_2 dark:text-brand-gray_1 text-center"
            >
              <div className="flex justify-center">
                <Image
                  src="/images/winners.png"
                  alt={item?.userName}
                  width={136}
                  height={136}
                  className="rounded-full w-[97px] h-[97px] md:w-[136px] md:h-[136px]"
                  unoptimized={true}
                />
              </div>
              <span className="font-bold text-xs md:text-xl my-2.5 block">
                @{item?.userName?item?.userName:"unknown"}
              </span>
              <div className="flex justify-center">
                <AwardIcon className="w-[22px] h-[22px] md:w-[30px] md:h-[30px] text-brand-darkGray_2 dark:text-white" />
              </div>
              <h4 className="font-bold text-xs md:text-xl text-brand uppercase mt-5 mb-3 w-[90%] ">
                {item?.product_title}
              </h4>
              <span className="flex flex-col">
                {/* <p className="font-semibold text-xs md:text-base mb-[4px]">
                  {`Ticket number(s) :`}
                  <span className="text-brand ps-2">{item?.ticket}</span>
                </p> */}
                <p className="font-semibold text-xs md:text-base">
                  {`Prize : `}
                  <span className="text-brand uppercase ps-2">
                  {item?.prize}
                  </span>
                </p>
              </span>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default RecentWinners;
