import React from "react";

const Heading = ({ title, mb, className, color }) => {
  return (
    <h2
      className={`font-bold ${className} font-stratos text-2xl ${mb? mb : 'mb-6'} ${
        mb ? mb : "lg:mb-14"
      } ${color ? color : "text-brand-dark dark:text-white"}
       md:text-4xl leading-[37px] md:leading-[50px]  uppercase`}
    >
      {title}
    </h2>
  );
};

export default Heading;
