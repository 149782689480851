'use client';
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { getUserCreditPoints } from '@/app/checkout/actions';

const UserCreditPoints = () => {
  const [myCreditPoints, setMyCreditPoints] = useState(0);

  useEffect(() => {
    const getCreditPoints = async () => {
      const { creditPoints } = await getUserCreditPoints();
      setMyCreditPoints(creditPoints);
    };

    getCreditPoints();
  }, []);

  return (
    <div className="border-[1px] border-solid border-brand-darkGray dark:border-brand-gray_1 rounded-xl w-[130px] py-1">
      <span className="flex gap-2 lg:gap-3 items-center justify-center text-xs lg:text-sm font-bold text-brand-darkGray dark:text-brand-dark dark:text-white">
        <Image src="/icons/star-primary-2.svg" alt="" width={24} height={22} />
        {`${myCreditPoints} SP`}
      </span>
    </div>
  );
};

export default UserCreditPoints;
