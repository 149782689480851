'use client';
import React, { useEffect, useState, useRef, Suspense } from 'react';
import dynamic from 'next/dynamic';
import styles from '@/assets/css/styles';
import { fetchAllLotteries } from '@/app/components/homepage/actions';
const LotteryCard = dynamic(() => import('./lotteryCard'));



const ProductList = ({ lotteries }) => {
  return (
    <div className={styles.giveawayGrid}>
      {lotteries?.map((p, i) => {
        return (
          <div key={i} className={`${styles.giveawayCard}`}>
            <LotteryCard p={p} />
          </div>
        );
      })}
    </div>
  );
};


const GiveawaysTabsContent = ({alllotteries}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [lotteries, setLotteries] = useState(alllotteries);
  
  const currentPage = useRef();

  useEffect(() => {
    const preFetchLotteries = async () => {
      const { products, pageInfo } = await fetchAllLotteries(null,12);

      currentPage.current = {
        products, pageInfo
      };

      setLotteries({
        products, pageInfo
      })

      loadMore()
      
    };

    if (!currentPage?.current?.products || currentPage?.current?.products?.all?.length<1) {
      preFetchLotteries();
    }
  }, []);



  const loadMore = () => {
    const getMoreLotteries = async () => {
      setLoadingMore(true);
      const { products, pageInfo } = await fetchAllLotteries(
        currentPage?.current?.pageInfo?.endCursor
      );

      currentPage.current.products = {
        all: [...currentPage?.current?.products?.all, ...products?.all],
        active: [...currentPage?.current?.products?.active, ...products?.active],
        instantwins: [
          ...currentPage?.current?.products?.instantwins,
          ...products?.instantwins,
        ],
        recentended: [
          ...currentPage?.current?.products?.recentended,
          ...products?.recentended,
        ],
      };
      currentPage.current.pageInfo = pageInfo;
      setLoadingMore(false);
      
    };

    setLotteries({
      pageInfo: currentPage?.current?.pageInfo,
      products: currentPage?.current?.products,
    });
    
    getMoreLotteries();
    
  };

  return (
    <>
      {/* All */}
      <section
        id="sec-1"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center  lg:pt-5"
      >
        <Suspense>
          <ProductList lotteries={lotteries?.products?.all} />
        </Suspense>
      </section>
      {/* Active Products */}
      <section
        id="sec-2"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center  lg:pt-5"
      >
        <Suspense>
          <ProductList lotteries={lotteries?.products?.active} />
        </Suspense>
      </section>

      {/* Instant wins */}
      <section
        id="sec-3"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center  lg:pt-5"
      >
        <Suspense>
          <ProductList lotteries={lotteries?.products?.instantwins} />
        </Suspense>
      </section>

      {/* recent ended lottery */}
      <section
        id="sec-4"
        className="mb-20 flex min-h-[600px] flex-wrap justify-center  lg:pt-5"
      >
        <Suspense>
          <ProductList lotteries={lotteries?.products?.recentended} />
        </Suspense>
      </section>

      {currentPage?.current?.pageInfo?.hasNextPage && (
        <div className="w-full mt-10 flex justify-center">
          <button
            onClick={() => loadMore()}
            className="text-brand-dark dark:text-white hover:text-dark bg-brand p-2 px-5 rounded-md min-w-40 "
            disabled={loadingMore}
          >
            {loadingMore ? "Wait..." : "Load more"}
          </button>
        </div>
      )}
    </>
  );
};

export default GiveawaysTabsContent;
